"use strict";

angular.module("informaApp")
    .service("DrugIndicationService", ['$http', 'BaseApiService', "ConstantsSvc", "FileDownloader", 'OmnitureSvc', function ($http, BaseApiService, ConstantsSvc, FileDownloader, OmnitureSvc) {
          return {
            getDrugIndications: function(params){
                return BaseApiService.post('drugindication', params)
                .then(x => x.data.data);

                // return $http.post(ConstantsSvc.API.URL + "drugindication", params).then(x => x.data.data);
            },
            exportExcel: function (ids, startDate, endDate, callback) {
                try {
                    var httpRequest = {
                        headers: {
                            'Content-type': 'application/json'
                        },
                        responseType: 'arraybuffer'
                    };
        
                    const cookies = document.cookie.split('; ');
                    for (let i = 0; i < cookies.length; i++) {
                        const cookie = cookies[i];
                        if (cookie.indexOf('token=') === 0) {
                            httpRequest.headers = {
                            ...httpRequest.headers,
                            Authorization: `Bearer ${cookie.split('=')[1]}`
                            };
                            break;
                        }
                    }
                    
                    var exportReq = $http(_.merge({
                            url: ConstantsSvc.API.URL + "drugindication/download",
                            method: 'POST',
                            data: {
                                ids: ids,
                                startDate: startDate,
                                endDate: endDate
                            },
                        }, httpRequest));
    
                    const filename = 'Drug-Indications.xlsx';
                    OmnitureSvc.export.trackMain(filename);

                    return FileDownloader.download(exportReq, filename, callback);
                }
                catch(e) {
                    console.log(`Drug Indication SVC - catch error:`, e)
                }
                
                /*
                ============================================
                
                const fileName = 'Drug-Indications.xlsx';

                FileDownloader.download(
                    BaseApiService.post('drugindication', {
                        ids: ids,
                        startDate: startDate,
                        endDate: endDate
                    }), 
                    fileName, 
                    callback);
                    
                    //************************
                        $http({
                        url: ConstantsSvc.API.URL + "drugindication/download",
                        method: "POST",
                        data:  {
                            ids: ids,
                            startDate: startDate,
                            endDate: endDate
                        },
                        headers: {
                            'Content-type': 'application/json'
                        },
                        responseType: 'arraybuffer'
                        })
                    ///
                OmnitureSvc.export.trackMain(fileName);
                */
            }
        };
    }]);